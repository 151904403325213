export const getBenefitsText = () => {
    return [
        {title: 'Минимизация рисков', text: 'Помогаю своим клиентам минимизировать риски, связанные с покупкой или продажей недвижимости, обнаруживая скрытые проблемы и предоставляя профессиональную оценку.'},
        {title: 'Экспертиза', text: 'Имею большой опыт и знания в области недвижимости, что позволяет мне помогать своим клиентам принимать правильные решения.'},
        {title: 'Защита интересов', text: 'Защищаю интересы своих клиентов в процессе сделки, гарантируя, что все условия договора будут для вас выгодны.'},
        {title: 'Управление сделкой', text: 'Управляю всей сделкой, от подписания договора до передачи ключей, облегчая процесс покупки или продажи недвижимости для своих клиентов.'},
        {title: 'Помощь в оформлении документов', text: 'Помогаю своим клиентам оформить все необходимые документы.'}
    ]
}

export const getReviewText = () => {
    return [
        {id: 1, name: 'Галина Васильевна Бородина', type: 'Продажа квартиры', date: 'Март 2019', text: 'Я хотела бы поделиться своим отзывом о продаже моей недвижимости, которая была продана при помощи Александра Владимировича.\n\nЯ была очень довольна профессионализмом и усердием моего риелтора, который помог мне продать мою квартиру. Он установил правильную рыночную цену, что привело к большому количеству заинтересованных покупателей.\n\nКроме того, он провел хорошо организованные показы и ответил на все вопросы покупателей. В результате моя квартира была продана в течение короткого времени по цене, которая устроила меня.\n\nЯ благодарна Александру Владимировичу за профессионализм и трудолюбие в процессе продажи моей недвижимости. Благодаря ему я получила выгодное предложение и успешно продала свою квартиру.\n\nЯ рекомендую его всем, кто ищет надежного и опытного профессионала в сфере недвижимости.'},
        {id: 2, name: 'Дмитрий Лобачевский', type: 'Покупка квартиры', date: 'Апрель 2021', text: 'Я хотел бы выразить огромную благодарность Александру Владимировичу, который помог мне получить ипотеку на покупку моей первой квартиры.\n\nОн оказал мне очень квалифицированную помощь, объяснив процесс получения ипотеки и помог собрать необходимые документы.'},
        {id: 3, name: 'Елена Сорокина', type: 'Покупка квартиры', date: 'Август 2022', text: 'Я хотела бы выразить благодарность Александру за его профессионализм и помощь в процессе покупки моей квартиры, он помог найти мне идеальный вариант жилья в моем любимом районе по цене, которая соответствовала моим возможностям.\n\nКроме того, он объяснил мне все детали процесса покупки, что было очень полезно для меня.\n\nАлександр был дружелюбным, отзывчивым и всегда был готов ответить на все мои вопросы. Процесс покупки квартиры был очень гладким и без стресса благодаря его профессиональному подходу.\n\nЯ очень довольна своей новой квартирой и быстротой, с которой была закрыта сделка. Я очень благодарна ему за его помощь и могу рекомендовать его и достаточно высоко.\n\nЕсли когда-нибудь мне снова понадобится помощь в покупке или продаже недвижимости, я обязательно обращусь к Александру еще раз.'}
    ]
}

export const getServices = () => {
    return [
        {id: 1, name: 'Продажа недвижимости', description: 'Обратившись ко мне можете быть уверены, что Ваша недвижимость будет продана быстро и по выгодной цене. Помогу определить рыночную стоимость недвижимости, организовать профессиональную фотосъемку объекта. Размещу объявления на ТОПовых площадках. Учту все юридические аспекты и гарантирую конфиденциальность сделки.'},
        {id: 2, name: 'Покупка недвижимости', description: 'Помогу Вам найти наиболее выгодное предложение на рынке отвечающий вашим потребностям и бюджету, проведу сделку безопасно и эффективно.'},
        {id: 3, name: 'Обмен недвижимости', description: 'Организую для владельцев недвижимости продать свое жилье и купить другую недвижимость, которая лучше соответствует их потребностям и желаниям. Благодаря этому, они могут получить удобное и комфортное жилье, не тратя много времени и сил на поиск и продажу каждого объекта.'},
        {id: 4, name: 'Ипотека', description: 'Помогу вам определить, какую ипотеку можно получить, какую недвижимость вы можете позволить и какие документы необходимо подготовить для получения ипотеки. Кроме того, помогу найти наиболее выгодные предложения на рынке и выбрать наилучшие условия ипотечного кредитования, исходя из ваших потребностей и финансовых возможностей.'},
        {id: 5, name: 'Юридическая экспертиза недвижимости', description: 'Проверю юридическую чистоту покупаемой недвижимости. Помогу Вам разобраться в документации, оценить юридические риски и гарантировать правомерность сделки.'},
        {id: 6, name: 'Сопровождение сделки', description: 'Помощь в проведении юридических процедур при покупке или продаже недвижимости. Помогаю грамотно оформить документы, оценить риски, провести переговоры и сделать все необходимые юридические процедуры.'},
        {id: 7, name: 'Выкуп недвижимости', description: 'Предлагаю гарантированный выкуп объекта, прозрачный договор и оперативный расчет наличными или безналичным способом. Готов выкупить любой объект недвижимости, в том числе объекты, имеющие аресты, обременения, залоги и юридические проблемы.'}
    ]
}